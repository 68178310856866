@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.App {
  display: block;
  height: 100vh;
  margin: 0;
}

.App-link {
  color: #61dafb;
}

.Disclaimer {
  font-family: IBM Plex Sans;
  font-size: 16px;
  color: #222222;
  position: absolute;
  bottom: 60px;
  left: 60px;
}

.avatar {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 45px;
  right: 60px;
}

.avatar a {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('./Flower\ —\ default.svg');
  background-size: cover;
  background-position: center;
}

.avatar a:hover {
  background-image: url('./Flower\ —\ hover.svg');
}

.avatar a:active {
  background-image: url('./Flower\ —\ active.svg');
}